<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">應收帳款管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" /> 匯出 CSV
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span>
            </div>
          </div>
        </div>
        <button v-show="!grid.isEditing && canCreate" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input type="search" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword" placeholder="請輸入應收帳款" />
          </div>
          <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
        <!-- <div class="flex mt-2 sm:mt-0">
          <button class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300" @click="grid.onPrint({ mode: 'curret' })">
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button>
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300">
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="initData"
          @removeSelectedRows="hideBatchDropDown()"
          @modalResize="onGridModalResize"
        >
          <template #link="{ row }">
            <div @click="linkModal.show(row)" class="inline-block bg-transparent text-blue-500 hover:underline cursor-pointer">
              <p>{{row.ProductNo}}</p>
            </div>
          </template>
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
              <template #column-CustomerId="{ data }">
                <select-box v-bind="selectorOptions" v-model="data.CustomerId" />
              </template>
              <template #column-employeeId="{ data }">
                <select-box v-bind="employeeSelectorOptions" v-model="data.EmployeeId" />
              </template>
              <template #column-PackagingDescription="{ data }">
                <string-select-box v-bind="packagingDescriptionSelectorOptions" v-model="data.PackagingDescription" />
              </template>
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button type="submit" status="primary" content="確認" @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"></vxe-button>
            <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
          </template>
          <template #filter-name="{ column, $panel }">
            <div v-for="(option, index) in column.filters" :key="index" class="flex item-center justify-center">
              <input type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)"
              />
              <div class="relative m-2">
                <div class="absolute rounded-l-md w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600">
                  {{option.label}}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)"
                />
              </div>
            </div>
          </template>
          <template #content="{ row }">
            <div class="m-5">
              <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                <tbody>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">品號</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.ProductNo }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">SKU</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.SKU }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">品名</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.ProductName }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">英文品名</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.EnglishProductName }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">包裝方式</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Package }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">英文包裝方式</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.EnglishPackage }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">標頭紙</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.TitlePaper }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">管芯</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.PaperTube }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">應收帳款尺寸</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.ProductSize }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">單位</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Uint }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">顏色</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Color }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">套/箱</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Radix }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">卷/套(束)</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Bundle }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">箱/板</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.PalletsRadix }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">@G.W</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.GW }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">@N.W</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.NW }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">CBM(立方米)</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.CBM }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">Cuft</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Cuft }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">單位價格</td>
                    <td class="border border-gray-400 py-2 px-4">{{ formatAmount(row.UnitPrice, 3) }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">客戶</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Customer?.Name }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">備註</td>
                    <td class="border border-gray-400 py-2 px-4">{{ row.Remark }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <vxe-modal ref="linkVXEModal" v-model="linkModal.visible" fullscreen title="顯示應收帳款資料" show-zoom="true">
      <div class="m-5">
              <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                <tbody>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">品號</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.ProductNo }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">SKU</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.SKU }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">品名</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.ProductName }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">英文品名</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.EnglishProductName }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">包裝方式</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Package }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">英文包裝方式</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.EnglishPackage }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">標頭紙</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.TitlePaper }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">管芯</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.PaperTube }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">應收帳款尺寸</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.ProductSize }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">單位</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Uint }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">顏色</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Color }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">套/箱</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Radix }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">卷/套(束)</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Bundle }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">箱/板</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.PalletsRadix }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">@G.W</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.GW }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">@N.W</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.NW }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">CBM(立方米)</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.CBM }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">Cuft</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Cuft }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">單位價格</td>
                    <td class="border border-gray-400 py-2 px-4">{{ formatAmount(linkModal.selectedRow?.UnitPrice, 3) }}</td>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">客戶</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Customer?.Name }}</td>
                  </tr>
                  <tr>
                    <td class="border border-gray-400 bg-gray-200 py-2 px-4">備註</td>
                    <td class="border border-gray-400 py-2 px-4">{{ linkModal.selectedRow?.Remark }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
    </vxe-modal>
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import StringSelectBox, { StringSelectBoxOptions } from '@/cloudfun/components/SelectStringBox.vue'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import ProductFileUpload from "@/components/file/ProductFileUpload.vue";
import { formatAmount } from '@/libs/utils'
import formatDate from 'xe-utils/toDateString'

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    StringSelectBox,
    ProductFileUpload,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;

    const checkPermissions = (key: string) => {
      // console.log(user?.Permissions)
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canCreate = checkPermissions("ProductCreate");
    const canUpdate = checkPermissions("ProductUpdate");
    const canDelete = checkPermissions("ProductDelete");

    const gridOptions: GridOptions = {
      stripe: false,
      title: '應收帳款管理',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      // canCreate: canCreate,
      // canUpdate: canUpdate,
      // canDelete: canDelete,
      multiselect: false,
      printConfig: { sheetName: '應收帳款管理清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '應收帳款管理清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        // {
        //   field: 'Name',
        //   title: '名稱',
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true,
        // },
        // {
        //   field: 'Number',
        //   title: '帳款編號',
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true,
        // },
        {
          field: 'Customer.Name',
          title: '客戶',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: 'InvoiceNumber',
          title: '銷貨單號',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: 'ShippingDate',
          title: '出貨日期',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, "yyyy/MM/dd") : ''
        },
        {
          field: 'ShippingAmount',
          title: '出貨單金額',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: 'UncollectedAmount',
          title: '未收金額',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: 'ExpectedAccountingDate',
          title: '預計入帳日',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, "yyyy/MM/dd") : ''
        },
        { 
          field: 'Status',
          title: '狀態', 
          showHeaderOverflow: true, 
          showOverflow: true, 
          sortable: true, 
          formatter: ({ cellValue }) => model ? Object.values(model.enums.AccountsReceivableStatus).find(e => e.Value === cellValue)?.Name : undefined 
        },
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          return model.dispatch('accountsReceivable/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('accountsReceivable/query') : undefined,
        save: model ? (params) => model.dispatch('accountsReceivable/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true, fullscreen: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'CustomerId',
          title: '客戶名稱',
          span: 12,
          slots: { default: "column-CustomerId" }
        },
        {
          field: 'InvoiceNumber',
          title: '銷貨單號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        // {
        //   field: 'Name',
        //   title: '名稱',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        {
          field: 'ShippingDate',
          title: '出貨日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'ExpectedAccountingDate',
          title: '預計入帳日',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'CurrencyType',
          title: '幣別',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', clearable: true } }
        },
        {
          field: 'ShippingAmount',
          title: '出貨單金額',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', clearable: true } }
        },
        {
          field: 'Tax',
          title: '稅額',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', clearable: true } }
        },
        {
          field: 'TotalAmount',
          title: '總金額',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', clearable: true } }
        },     
        {
          field: 'UncollectedAmount',
          title: '未收金額',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', clearable: true } }
        },
        {
          field: 'RemittanceAmount',
          title: '已收金額',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', clearable: true } }
        },
        {
          field: 'Allowance',
          title: '折讓金額',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', clearable: true } }
        },            
        {
          field: 'RemittanceDate',
          title: '已收款日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'JobTickeNumber',
          title: '工單號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'EmployeeId',
          title: '業務',
          span: 12,
          slots: { default: "column-employeeId" }
        },
        {
          field: 'SPIMAX',
          title: 'SP/I-MAX',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Remark',
          title: '備註',
          span: 24,
          itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, placeholder: '請輸入文字' } }
        },   
        {
          field: 'Number',
          title: '帳款編號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Status',
          title: '簽核狀態',
          span: 12,
          itemRender: { name: "$select", props: {}, options: model ? Object.values(model.enums.AccountsReceivableStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        // {
        //   field: 'RemittanceExchangeRate',
        //   title: '匯款匯率',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', clearable: true } }
        // },
        // {
        //   field: 'OrderExchangeRate',
        //   title: '匯款匯率',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', clearable: true } }
        // },
        // {
        //   field: 'RedemptionNumber',
        //   title: '沖款單號',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        // {
        //   field: 'IncomeDate',
        //   title: '新加坡進款日',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        // },
        // {
        //   field: 'SGRemittanceAmount',
        //   title: '匯款金額(新加坡)',
        //   span: 12,
        //   itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', clearable: true } }
        // },
        // {
        //   field: 'SGRemark',
        //   title: '備註(新加坡)',
        //   span: 24,
        //   itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, placeholder: '請輸入文字' } }
        // },
      ],
      rules: {
        //CustomerId: [{ required: true }],
        Name: [{ type: 'string', required: true }],
      }
    }

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Number",
          title: "客戶代號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },        
      ],
      showHeader: true,
      promises: {
        find: async (value: any) => await model!.dispatch("customer/find", value), // eslint-disable-line
        query: async (params: any) => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          return await model!.dispatch("customer/query", params) // eslint-disable-line
        }
      }
    };

    const initData = (row: any, callback: any) => {
      row.SGRemittanceAmount = 0;
      row.Status = 0;
      row.OrderExchangeRate = 0;
      row.RemittanceAmount = 0;
      row.RemittanceExchangeRate = 0;
      row.UncollectedAmount = 0;
      row.ShippingAmount = 0;
      callback();
    }

    const unique = (data: any, name: string) => {
      let result = [
        ...new Set(data.map((e: any) => e[name])),
      ];
      return result;
    };

    const linkVXEModal = ref<any>();
    const linkModal = reactive({
      visible: false,
      selectedRow: {} as any,
      show(data: any) {
        linkModal.visible = true;
        linkModal.selectedRow = data;
        linkVXEModal.value.maximize()
      },
    })

    const employeeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Person.Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: (value: any) => model!.dispatch("employee/find", value), // eslint-disable-line
        query: (params: any) => {
          params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          return model!.dispatch("employee/query", params) // eslint-disable-line
        }
      }
    };

    return {
      grid,
      gridOptions,
      formOptions,
      model,
      selectorOptions,
      initData,
      canCreate,
      linkVXEModal,
      linkModal,
      formatAmount,
      employeeSelectorOptions,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
  }
})
</script>
